.back-to-top-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.back-to-top-button.visible {
  opacity: 1;
}

.arrow-icon {
  color: #fff;
  font-size: 24px;
}
