.App {
  text-align: center;
  background-color: #121212;
  color: white;
  position: relative;

  padding-bottom: 2.5rem;
}

p {
  color: #adadad;
  /* font-size: 1.5vw; */
}

b {
  color: #90ee90;
}

a {
  color: #90ee90;
  font-weight: bold;
  text-decoration: None;
}

a:hover {
  color: #90ee90;
  text-decoration: underline;
}

.content {
  padding-bottom: 2.5rem; /* Footer height */
  min-height: 100vh;
}

.myRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.myCol {
  flex: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

code {
  color: #18ffff;
  font-size: 1.1em;
}

li {
  list-style-position: inside;
}

.myList::before {
  content: "🖥️";
  margin-left: -20px;
  margin-right: 10px;
  font-size: 1.4em;
}

.myList2::before {
  content: "👨‍💻";
  margin-left: -20px;
  margin-right: 10px;
  font-size: 1.4em;
}
.myList3::before {
  content: "💾";
  margin-left: -20px;
  margin-right: 10px;
  font-size: 1.4em;
}
.myList4::before {
  content: "🖱️";
  margin-left: -20px;
  margin-right: 10px;
  font-size: 1.4em;
}
.myList5::before {
  content: "⌨️";
  margin-left: -20px;
  margin-right: 10px;
  font-size: 1.4em;
}
.myList6::before {
  content: "💻";
  margin-left: -20px;
  margin-right: 10px;
  font-size: 1.4em;
}
.myList7::before {
  content: "🌐";
  margin-left: -20px;
  margin-right: 10px;
  font-size: 1.4em;
}
.myList8::before {
  content: "📊";
  margin-left: -20px;
  margin-right: 10px;
  font-size: 1.4em;
}
.myList9::before {
  content: "⚙️";
  margin-left: -20px;
  margin-right: 10px;
  font-size: 1.4em;
}
.myList10::before {
  content: "🛰️";
  margin-left: -20px;
  margin-right: 10px;
  font-size: 1.4em;
}

.container {
  width: 70%;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.cardStyle2:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card {
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  background-color: #242424;
}

.cardStyle2 {
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  background-color: #242424;
}

::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #666; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Add border-radius to give a rounded appearance */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #888; /* Set the color when hovering over the scrollbar thumb */
}

::-webkit-scrollbar-track {
  background-color: #222; /* Set the color of the scrollbar track */
}

.codeRow {
  display: flex;
  justify-content: space-between;
}

.codeCol {
  flex: 0 0 48%;
  text-align: left;
  overflow: hidden;
  max-height: 500px;
}
